<template>
  <v-container fluid class="pa-0"> 
    <v-card class="d-flex flex-column fill-height">
        <v-card-title class="chat-header">
          <label><span>{{ $t("navbar.coreBuying") }}</span></label>
        </v-card-title>
        <v-row class="no-gutters elevation-0">
          <v-col primary xs="3" sm="3">
            <v-card-text style="margin-top: 25px">
                      <v-row>
                        <v-col primary xs="12" sm="12">
                          <v-autocomplete v-model="searchByParts" :items="partsArrayList"
                            :label="$t('coreBuying.searchParts')" :value="searchByParts" item-text="Description"
                            item-value="Description" outlined clearable multiple deletable-chips small-chips
                            hide-selected hide-no-data hide-details :menu-props="{
                              closeOnContentClick: true,
                            }">
                          </v-autocomplete>
                        </v-col>
                        <v-col primary xs="12" sm="12">
                          <v-autocomplete v-model="searchByCoreBuyingUsers" :items="coreBuyingUsersList.data"
                            :label="$t('coreBuying.searchCoreBuying')" :value="searchByCoreBuyingUsers" item-text="company_name"
                            item-value="id" outlined clearable multiple deletable-chips small-chips
                            hide-selected hide-no-data hide-details :menu-props="{
                              closeOnContentClick: true,
                            }">
                          </v-autocomplete>
                        </v-col>
                        <v-col primary xs="12" sm="12">
                          <v-btn :loading="loading" hide-details="auto" :disabled="loading" large
                            color="green darkan-4 green-gradient white--text" class="text-capitalize btn-submit mt-4"
                            @click="fetchCoreBuyingList">
                            {{ $t("profile.submit") }}
                            <template v-slot:loading>
                              <v-icon>mdi-loading</v-icon>
                            </template>
                          </v-btn>
                        </v-col>
                      </v-row>
             </v-card-text>
          </v-col>
          <v-col primary xs="9" sm="9">
            <div class="custom-card" style="box-shadow: none;">
                <div class="custom-box">
                    <v-data-table class="core_buyer_table" :header-props="{ sortIcon: null }" :headers="headers" :items="coreBuyingSearchList.data"
                      :single-expand="singleExpand" :expanded.sync="expanded" fixed-header
                      :disable-sort="true" :show-expand="false" :loading="loading" :page.sync="page"
                      :items-per-page="itemsPerPage" @page-count="pageCount = $event" hide-default-footer>
                      <template v-slot:item.created_at="{ item }">
                        {{ item.created_at | MMDDYYdatefilter }}
                      </template>
                      <template v-slot:item.company_name="{ item }">
                        {{ item?.user?.user_detail?.company_name }}
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              style="cursor: pointer;"
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >mdi-information-outline</v-icon>
                          </template>
                          <span>
                            <span v-if="item?.user?.user_detail?.company_email"><b>Email:</b> {{ item?.user?.user_detail?.company_email }}<br></span>
                            <span v-if="item?.user?.user_detail?.company_phone"><b>Phone:</b> {{ item?.user?.user_detail?.company_phone | USFormatNumber }}<br></span>
                            <span v-if="item?.user?.user_detail?.website"><b>Website:</b> {{ item?.user?.user_detail?.website }}<br></span>
                            <span v-if="item?.user?.user_detail?.address"><b>Address:</b> {{ item?.user?.user_detail?.address }}<br></span>
                          </span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item.company_phone="{ item }">
                        {{ item?.user?.user_detail?.company_phone | USFormatNumber }}
                      </template>
                      <template v-slot:item.name="{ item }">
                        {{ item.name }}
                      </template>
                      <template v-slot:item.parts="{ item }">
                        {{ getPartsJoin(item.parts) }}
                      </template>
                      <template v-slot:item.action="{ item }">
                        <v-row v-if="item?.media">
                          <v-btn height="40" small color="green darken-4 green-gradient white--text" class="mr-2"
                            @click="viewFile(item)">
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                          <v-btn height="40" small color="red darken-4 red-gradient white--text" class="mr-2" @click="downloadFile(item)">
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </v-row>
                      </template>
                    </v-data-table>
                    <div class="text-center">
                      <v-pagination v-model="page" :length="pageCount" @input="fetchCoreBuyingList"></v-pagination>
                    </div>
                </div>
            </div>  
          </v-col>
        </v-row>
      </v-card>
  </v-container>            
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import CurrentBroadcasts from "../components/CurrentBroadcasts";
// Janus Related files
import "@/plugins/yoyo";

import ConfirmationDialog from "../components/common/ConfirmationDialog";
import { mapActions, mapGetters } from "vuex";
import { ITEMS_PER_PAGE } from "@/constants/common";

export default {
  components: {
    Navbar,
    Footer,
    CurrentBroadcasts,
    ConfirmationDialog,
    GooglePlace: () => import("@/components/common/GooglePlace"),
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },

  data() {
    return {
      panel: [0],
      saving: false,
      error: "",
      loading: false,
      searchByParts: [],
      searchByCoreBuyingUsers: [],
      singleExpand: true,
      expanded: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 0,
      apiErrorMessage: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      coreBuyingSearchList: "coreBuying/getCoreBuyingSearch",
      coreBuyingUsersList: "coreBuying/getCoreBuyingUsers",
      partsArrayList: "coreBuying/getCoreBuyingParts",
    }),
    headers() {
      return [
        {
          text: this.$t("coreBuying.date"),
          align: "left",
          value: "created_at",
          width: "10%",
        },
        {
          text: this.$t("coreBuying.companyName"),
          align: "left",
          value: "company_name",
          width: "10%",
        },
        {
          text: this.$t("coreBuying.companyPhone"),
          align: "left",
          value: "company_phone",
          width: "10%",
        },
        {
          text: this.$t("coreBuying.titleList"),
          align: "left",
          value: "title",
          width: "18%",
        },
        {
          text: this.$t("coreBuying.parts"),
          align: "left",
          value: "parts",
          width: "32%",
        },
        {
          text: this.$t("coreBuying.action"),
          align: "left",
          value: "action",
          width: "15%",
        },
      ];
    },
  },
  async mounted() {
    this.fetchParts();
    this.fetchCoreBuyingUsers();
    this.fetchCoreBuyingList();
  },
  methods: {
    ...mapActions({
      getCoreBuyingSearch: "coreBuying/getCoreBuyingSearch",
      getCoreBuyingUsers: "coreBuying/getCoreBuyingUsers",
      getCoreBuyingParts: "coreBuying/getCoreBuyingParts",
    }),

    async fetchParts() {
      this.loading = true;
      try {
        await this.getCoreBuyingParts();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
    async fetchCoreBuyingUsers() {
      this.loading = true;
      try {
        await this.getCoreBuyingUsers({});
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
    async fetchCoreBuyingList() {
      this.loading = true;
      try {
        if (this.searchText !== "") {
          this.page = 1;
        }
        await this.getCoreBuyingSearch({
          search: this.searchText,
          items_per_page: ITEMS_PER_PAGE,
          page: this.page,
          parts: this.searchByParts,
          users: this.searchByCoreBuyingUsers,
        });

        if (this.coreBuyingSearchList.meta) {
          this.itemsPerPage = parseInt(this.coreBuyingSearchList.meta.per_page);
          this.pageCount = this.coreBuyingSearchList.meta.last_page;
        } else {
          this.itemsPerPage = this.coreBuyingSearchList.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
    async viewFile(item) {
      if(item?.media) {
        console.log(process.env.VUE_APP_MEDIA_UPLOAD_SERVER+'public/redline/document/'+item.media.id+'/core-buying-file.xls');
        window.open("https://view.officeapps.live.com/op/view.aspx?src="+process.env.VUE_APP_MEDIA_UPLOAD_SERVER+'public/redline/document/'+item.media.id+'/core-buying-file.xls', '_blank');
      }
    },
    async downloadFile(item) {
      if(item?.media) {
        window.open(process.env.VUE_APP_MEDIA_UPLOAD_SERVER+'public/redline/document/'+item.media.id+'/core-buying-file.xls', '_blank');
      }
    },
    getPartsJoin(parts) {
      if(parts && parts.length > 0) {
        return parts.map(part => part.Description).join(', ');
      }
    }
  },
};
</script>